import styled from 'styled-components'

const Content = styled.div`
  position: relative;
  padding-top: ${props => props.paddingTop || 20}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${props => props.maxWidth || 500}px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;
`

export default Content
