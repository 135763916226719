import styled from 'styled-components'

const Title = styled.h1`
  text-align: center;
  color: #fff;
  font-family: 'Archivo', sans-serif;
  font-size: ${props => props.fontSize || 40}px;
  margin: 0;
  font-weight: 400;
`

export default Title
