import React, { Component } from 'react'
import styled from 'styled-components'
import { ReactComponent as Clock } from '../../assets/clock.svg'
import secondsToHoursMinutesSeconds from '../../utils/secondsToHoursMinutesSeconds'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Number = styled.p`
  color: #fff;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  letter-spacing: -0.1rem;
  margin: 4px 0 0 4px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`

let timer

class Timer extends Component {
  constructor(props: Props) {
    super(props)

    this.target = null
    this.pausedTime = null

    this.state = {
      countDown: props.seconds,
    }
  }

  componentDidMount = () => {
    this._interval(true)
  }

  componentWillUnmount = () => {
    clearTimeout(timer)
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.paused === false && this.props.paused === true) {
      this.pausedTime = Date.now()
    }
    if (prevProps.paused === true && this.props.paused === false) {
      // When unpausing, add the amount of time that was paused to the target time
      this.target = this.target + (Date.now() - this.pausedTime)
      this.pausedTime = null
    }

    if (prevProps.seconds !== this.props.seconds) {
      this._interval(true)
    }
  }

  _interval = start => {
    if (start) {
      this.target = Date.now() + this.props.seconds * 1000
    }
    timer = setTimeout(this._step, 100)
  }

  _step = () => {
    if (!this.props.paused) {
      const diff = this.target - Date.now()
      const seconds = Math.ceil(diff / 1000)

      if (seconds !== this.state.seconds) {
        this.setState({
          countDown: seconds,
        })
      }

      if (seconds > 0) {
        this._interval()
      }
    }
    else {
      this._interval()
    }
  }

  render = () => (
    <Container {...this.props}>
      <Clock />
      <Number>{secondsToHoursMinutesSeconds(this.state.countDown)}</Number>
    </Container>
  )
}

export default Timer
