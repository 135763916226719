import React from 'react'
import styled from 'styled-components'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import LinesBackground from '../components/backgrounds/LinesBackground'
import Content from '../components/position/Content'
import TopLeft from '../components/position/TopLeft'
import Title from '../components/text/Title'
import BodyText from '../components/text/BodyText'
import Button from '../components/buttons/Button'
import CloseButton from '../components/buttons/CloseButton'
import LevelChamonix from '../assets/levelChamonix.jpg'
import LevelYosemite from '../assets/levelYosemite.jpg'
import LevelZermatt from '../assets/levelZermatt.jpg'
import LevelSquamish from '../assets/levelSquamish.jpg'
import { ReactComponent as Locked } from '../assets/locked.svg'

const LevelsPair = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`

const Level = styled.figure`
  flex: 1;
  margin: 0;
  border-radius: 8px;
  background: #000;
  padding: 8px;
  text-align: left;

  &:first-child {
    margin-right: 16px;
  }
`

const Image = styled.div`
  background: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  height: 124px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin: 8px;
  margin-bottom: 4px;
`

const Caption = styled.figcaption`
  flex: 1;
  color: ${props => (props.disabled ? '#7f7f7f' : '#fff')};
  font-family: 'Archivo', sans-serif;
  font-size: 14px;
  font-weight: 400;
`

const Levels = ({ navigate }) => (
  <>
    <MapBackground />
    <LinesBackground />

    <Content maxWidth={600}>
      <Title>LEVELS</Title>

      <BodyText maxWidth={400}>Choose a level to play</BodyText>

      <LevelsPair>
        <Level as={Button} onClick={() => navigate.changeScreen(screens.CALIBRATE_SCREEN)}>
          <Image src={LevelChamonix} />
          <CaptionContainer>
            <Caption>Chamonix, France</Caption>
          </CaptionContainer>
        </Level>

        <Level>
          <Image src={LevelYosemite} />
          <CaptionContainer>
            <Caption disabled>Yosemite Valley, CA</Caption>
            <Locked />
          </CaptionContainer>
        </Level>
      </LevelsPair>

      <LevelsPair>
        <Level>
          <Image src={LevelZermatt} />
          <CaptionContainer>
            <Caption disabled>Zermatt, Switzerland</Caption>
          </CaptionContainer>
        </Level>

        <Level>
          <Image src={LevelSquamish} />
          <CaptionContainer>
            <Caption disabled>Squamish, BC, Canada</Caption>
            <Locked />
          </CaptionContainer>
        </Level>
      </LevelsPair>
    </Content>

    <TopLeft>
      <CloseButton onClick={() => navigate.reset()} />
    </TopLeft>
  </>
)

export default Levels
