import React, { Component } from 'react'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import LinesBackground from '../components/backgrounds/LinesBackground'
import ControlImages from '../components/controls/ControlImages'
import Content from '../components/position/Content'
import TopLeft from '../components/position/TopLeft'
import Title from '../components/text/Title'
import BodyText from '../components/text/BodyText'
import CloseButton from '../components/buttons/CloseButton'
import { ReactComponent as BeastmakerHang } from '../assets/beastmakerHang.svg'

let timer

class Calibrate extends Component {
  componentDidMount = () => {
    // Detect a hang and go to next screen

    // Using a timer for now to simulate someone hanging
    timer = setTimeout(() => {
      this.props.navigate.changeScreen(screens.LEVEL_ONE_SCREEN)
    }, 1500)
  }

  componentWillUnmount = () => {
    timer && clearTimeout(timer)
  }

  render = () => (
    <>
      <MapBackground />
      <LinesBackground />

      <Content>
        <Title>CALIBRATE</Title>

        <BodyText maxWidth={400}>Hang from the board so we can determine the size of your payload.</BodyText>

        <ControlImages>
          <BeastmakerHang />
        </ControlImages>
      </Content>

      <TopLeft>
        <CloseButton onClick={() => this.props.navigate.reset()} />
      </TopLeft>
    </>
  )
}

export default Calibrate
