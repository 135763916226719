const Cesium = window.Cesium

export const getPointDirectlyBelow = (point, scene) => {
  // Draw a ray from the point given to the centre of the Earth and
  // get the point where it intersects with the terrain.

  // the centre of the earth should be at (0,0,0) - so we can get its
  // direction by negating the point
  let centreDirection = new Cesium.Cartesian3()
  centreDirection = Cesium.Cartesian3.negate(point, centreDirection)
  const ray = new Cesium.Ray(point, centreDirection)
  const intersectionPoint = scene.globe.pick(ray, scene)
  return intersectionPoint
}

export const getAGL = (cartographic, scene) => {
  return cartographic.height - scene.globe.getHeight(cartographic)
}

export const getCartographicInDirection = (cartographic, direction, distance = 1) => {
  let point = new Cesium.Cartesian3()
  Cesium.Cartographic.toCartesian(cartographic, null, point)
  // Create a transformation matrix at this point
  let surfaceTransform = new Cesium.Matrix4()
  surfaceTransform = Cesium.Transforms.eastNorthUpToFixedFrame(point, null, surfaceTransform)

  // Create a new point in local frame in direction and distance and transform it back to the ellipsoid
  let newPoint = new Cesium.Cartesian3(distance * Math.cos(direction), distance * Math.sin(direction), 0)
  newPoint = Cesium.Matrix4.multiplyByPoint(surfaceTransform, newPoint, newPoint)
  let result = new Cesium.Cartographic()
  return Cesium.Cartographic.fromCartesian(newPoint, null, result)
}

export const getSurfaceGradient = (point, direction, scene, distance = 1) => {
  // Get the gradient angle (in radians) at a given point `point` in the given
  // direction `direction`, over a distance `distance`.

  // Project the point onto the ellipsoid
  let projectedPoint = new Cesium.Cartographic()
  projectedPoint = Cesium.Cartographic.fromCartesian(point, null, projectedPoint)
  projectedPoint.height = 0

  // Create a transformation matrix at this point
  let surfaceTransform = new Cesium.Matrix4()
  surfaceTransform = Cesium.Transforms.eastNorthUpToFixedFrame(point, null, surfaceTransform)

  // Create a new point in local frame in direction and distance and transform it back to the ellipsoid
  let newPoint = new Cesium.Cartesian3(distance * Math.cos(direction), distance * Math.sin(direction), 0)
  newPoint = Cesium.Matrix4.multiplyByPoint(surfaceTransform, newPoint, newPoint)

  // Convert to cartographic and use to get height
  let projectedPoint2 = new Cesium.Cartographic()
  projectedPoint2 = Cesium.Cartographic.fromCartesian(newPoint, null, projectedPoint2)

  const diffHeight = scene.globe.getHeight(projectedPoint2) - scene.globe.getHeight(projectedPoint)
  if (isNaN(diffHeight) || diffHeight == null) {
    return 0
  }
  return Math.atan2(diffHeight, distance)
}
