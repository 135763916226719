import React from 'react'
import Button from './Button'
import { ReactComponent as Play } from '../../assets/play.svg'

const PlayButton = props => (
  <Button {...props}>
    <Play />
  </Button>
)

export default PlayButton
