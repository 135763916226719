let websocket = null

const connectionFailed = () => {
  if (websocket) {
    websocket.close()
  }

  setTimeout(init, 3000)
}

export const init = () => {
  try {
    websocket = new WebSocket('ws://172.24.1.1:10724/')

    setTimeout(() => {
      if (websocket.readyState !== 1) {
        connectionFailed()
      }
      else {
        console.log('websocket connected')
      }
    }, 5000)
  }
  catch (error) {
    console.log('websocket error', error)

    connectionFailed()
  }
}

export const isConnected = () => websocket && websocket.readyState === 1

export const onmessage = callback => {
  if (isConnected() && websocket) {
    websocket.onmessage = callback
  }
}
