import styled from 'styled-components'

const BodyText = styled.p`
  text-align: center;
  color: #00dd81;
  font-family: 'hack', sans-serif;
  font-size: 14px;
  font-weight: 400;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
`

export default BodyText
