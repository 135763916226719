import styled from 'styled-components'

const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 35px;
  left: 35px;
`

export default TopLeft
