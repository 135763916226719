import StraightSection from './StraightSection'

const Cesium = window.Cesium

let LEVELS = []

export class Route {
  constructor(sections) {
    this.sections = sections
    this.sections.forEach((section, i) => {
      section.id = i
    })
  }

  getNextSection(sectionId) {
    return this.sections[sectionId + 1]
  }

  getPrevSection(sectionId) {
    return this.sections[sectionId + 1]
  }
}

export class Level {
  constructor(id, routes) {
    this.id = id
    this.routes = routes
    this.routes.forEach((route, i) => {
      route.id = i
    })
  }

  getSection(routeId, sectionId) {
    return this.routes[routeId].sections[sectionId]
  }

  getRoute(routeId) {
    return this.routes[routeId]
  }

  getNextRoute(routeId) {
    return this.routes[routeId + 1]
  }

  getPrevRoute(routeId) {
    return this.routes[routeId - 1]
  }
}

export class LevelLoader {
  getLevel(levelId) {
    return LEVELS[levelId]
  }
}


LEVELS.push(new Level(0, [
  new Route([
    new StraightSection({
      startPosition: [7.012339, 45.900801, 3972],
      startHeading: Cesium.Math.toRadians(-10),
      startPitch: Cesium.Math.toRadians(-10),
    }),
    new StraightSection({
      startPosition: [7.01018033, 45.90934697, 3802],
      startHeading: Cesium.Math.toRadians(20),
      startPitch: Cesium.Math.toRadians(0),
    }),
    new StraightSection({
      startPosition: [7.01449830, 45.91762716, 3803],
      startHeading: Cesium.Math.toRadians(-33),
      startPitch: Cesium.Math.toRadians(25),
    }),
    new StraightSection({
      startPosition: [7.00826617, 45.92432426, 4216],
      startHeading: Cesium.Math.toRadians(-25),
      startPitch: Cesium.Math.toRadians(-20),
    }),
  ]),
  new Route([
    new StraightSection({
      startPosition: [6.952279, 45.947256, 3300],
      startHeading: Cesium.Math.toRadians(-100),
      startPitch: Cesium.Math.toRadians(-25),
    }),
    new StraightSection({
      startPosition: [6.94100344, 45.94586847, 2885.896],
      startHeading: Cesium.Math.toRadians(-120),
      startPitch: Cesium.Math.toRadians(-30),
    }),
  ]),
]))
