import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import Spinner from 'react-spinkit'
import GameState from '../state/GameState'
import Timer from '../components/inGame/Timer'
import Score from '../components/inGame/Score'
import TopLeft from '../components/position/TopLeft'
import TopRight from '../components/position/TopRight'
import BottomLeft from '../components/position/BottomLeft'
import CloseButton from '../components/buttons/CloseButton'
import PauseButton from '../components/buttons/PauseButton'
import PlayButton from '../components/buttons/PlayButton'
import Content from '../components/position/Content'
import Title from '../components/text/Title'
import BodyText from '../components/text/BodyText'
import TextButton from '../components/buttons/TextButton'
import screens from '.'

const Cesium = window.Cesium

const FullOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #171b23;
`

const PartialOverlay = styled.div`
  position: absolute;
  top: 15px;
  left: 100px;
  right: 100px;
  bottom: 15px;
  background: #171b23;
  border-radius: 6px;
  padding: 15px;
`

const Bottom = styled.div`
  position: fixed;
  bottom: 40px;
  text-align: center;
`

const CloseButtonStyled = styled(CloseButton)`
  margin-top: 15px;
`

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 45%;
  text-align: center;
`

const pulse = keyframes`
  from% {
    font-size: 40px;
  }

  to {
    font-size: 50px;
  }
`

const Warning = styled(Title)`
  color: red;
  animation: ${pulse} 0.5s infinite;
  animation-direction: alternate;
`

class Game extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      state: 'PENDING',
      paused: false,
      score: 0,
      timerSeconds: 0,
      timerPaused: false,
      hangWarning: false,
    }

    this.mode = props.screenOptions.mode || 'main'
  }

  componentDidMount = () => {
    Cesium.Ion.defaultAccessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyOWNkNjYzZS1iMjQ4LTQ3NmEtYmQ3NS1lOGIzNjM2OTcwZTciLCJpZCI6NjEyNSwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0NTI5ODI0OH0._w2p_GTz8CWw33v1KHIN4vQbRTWwUVOByYCrJ-KIm8w' // eslint-disable-line max-len

    this.viewer = new Cesium.Viewer('cesiumContainer', {
      terrainProvider: Cesium.createWorldTerrain(),
      animation: false,
      baseLayerPicker: false,
      fullscreenButton: false,
      geocoder: false,
      homeButton: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      timeline: false,
      navigationHelpButton: false,
      navigationInstructionsInitiallyVisible: false,
      creditContainer: 'cesiumCredits',
      shadows: true,
      shouldAnimate: true,
      targetFrameRate: 60,
    })

    this.gameState = new GameState(this.viewer, this, {
      mode: this.mode,
      eventHandler: (event, data) => {
        switch (event) {
          case 'started':
            this.setState({ state: 'STARTED' })
            break

          case 'completed':
            this.setState({ state: 'COMPLETED' })
            break

          case 'failed':
            this.setState({ state: 'FAILED' })
            break

          case 'paused':
            this.setState({ paused: true })
            break

          case 'resumed':
            this.setState({ paused: false })
            break

          case 'scoreChanged':
            this.setState({ score: data })
            break

          case 'timerChanged':
            this.setState({ timerSeconds: data })
            break

          case 'timerPaused':
            this.setState({ timerPaused: true })
            break

          case 'timerResumed':
            this.setState({ timerPaused: false })
            break

          case 'hangWarningChanged':
            this.setState({ hangWarning: data })
            break

          default:
            break
        }
      },
    })

    this.gameState.setup()

    this._checkIfLoaded()
  }

  componentWillUnmount = () => {
    this.viewer.destroy()
  }

  // poll gameState.isLoaded
  _checkIfLoaded = () => {
    if (this.gameState.isLoaded()) {
      this.setState({ loaded: true })
    }
    else {
      setTimeout(this._checkIfLoaded, 200)
    }
  }

  render() {
    return (
      <>
        <div id="cesiumContainer" />

        {this.state.state !== 'PENDING' && (
          <>
            {this.mode !== 'freeFly' &&
              this.state.state === 'STARTED' && (
                <>
                  <TopRight>
                    <Timer seconds={this.state.timerSeconds} paused={this.state.timerPaused} />
                  </TopRight>

                  {this.state.hangWarning && (
                    <Middle>
                      <Warning>KEEP GOING!</Warning>
                    </Middle>
                  )}

                  <BottomLeft>
                    <Score score={this.state.score} />
                  </BottomLeft>
                </>
            )}

            {this.state.state === 'STARTED' && (
              <>
                {!this.state.paused && (
                  <TopLeft>
                    <PauseButton onClick={() => this.gameState.pause()} />
                  </TopLeft>
                )}

                {this.state.paused && (
                  <>
                    <PartialOverlay>
                      <Content>
                        <Title>PAUSED</Title>
                      </Content>
                    </PartialOverlay>
                    <TopLeft>
                      <PlayButton onClick={() => this.gameState.resume()} />
                      <CloseButtonStyled onClick={this.props.navigate.reset} />
                    </TopLeft>
                  </>
                )}
              </>
            )}

            {this.state.state === 'COMPLETED' && (
              <>
                <PartialOverlay>
                  <Content>
                    <Title>{this.state.score}</Title>
                    <Title>MISSION COMPLETE</Title>

                    <Bottom>
                      <TextButton
                        minWidth={230}
                        onClick={() => this.props.navigate.changeScreen(screens.LEVEL_ONE_SCREEN)}
                      >
                        NEXT LEVEL
                      </TextButton>
                    </Bottom>
                  </Content>
                </PartialOverlay>
                <TopLeft>
                  <CloseButtonStyled onClick={this.props.navigate.reset} />
                </TopLeft>
              </>
            )}

            {this.state.state === 'FAILED' && (
              <>
                <PartialOverlay>
                  <Content>
                    <Title>{this.state.score}</Title>
                    <Title>MISSION FAILED</Title>

                    <Bottom>
                      <TextButton
                        minWidth={230}
                        onClick={() => this.props.navigate.changeScreen(screens.LEVEL_ONE_SCREEN)}
                      >
                        TRY AGAIN
                      </TextButton>
                    </Bottom>
                  </Content>
                </PartialOverlay>
                <TopLeft>
                  <CloseButtonStyled onClick={this.props.navigate.reset} />
                </TopLeft>
              </>
            )}
          </>
        )}

        {this.state.state === 'PENDING' && (
          <FullOverlay>
            <Content>
              <Title>MISSION OBJECTIVE</Title>

              {this.mode === 'freeFly' && (
                <BodyText maxWidth={400}>Fly the helicopter around the Chamonix landscape.</BodyText>
              )}

              {this.mode === 'main' && (
                <BodyText maxWidth={400}>
                  Weather conditions in the Chamonix valley have suddenly turned trecherous. 14 mountaineers are injured
                  and unable to make it to safety. Fly to each area to rescue the Alpinists. Score at least 1000 to
                  complete the mission.
                </BodyText>
              )}

              <Bottom>
                {!this.state.loaded && (
                  <>
                    <Title fontSize={20}>LOADING</Title>
                    <Spinner name="three-bounce" fadeIn="none" color="#FFF" />
                  </>
                )}

                {this.state.loaded && (
                  <TextButton minWidth={230} onClick={() => this.gameState.start()}>
                    I'M READY
                  </TextButton>
                )}
              </Bottom>
            </Content>

            <TopLeft>
              <CloseButton onClick={this.props.navigate.reset} />
            </TopLeft>
          </FullOverlay>
        )}
        <div id="cesiumCredits" style={{ position: 'absolute', left: -99999 }} />
      </>
    )
  }
}

export default Game
