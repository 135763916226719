import React from 'react'
import Button from './Button'
import { ReactComponent as Pause } from '../../assets/pause.svg'

const PauseButton = props => (
  <Button {...props}>
    <Pause />
  </Button>
)

export default PauseButton
