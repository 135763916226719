import styled from 'styled-components'

const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 35px;
  right: 35px;
`

export default TopRight
