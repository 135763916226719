import React from 'react'

import styled from 'styled-components'

const Label = styled.p`
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  letter-spacing: -0.05rem;
  margin: 0;
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`

const Number = styled.p`
  color: #fff;
  font-size: 32px;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  letter-spacing: -0.1rem;
  margin: 0;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`

const Score = ({ score = 0, ...props }) => (
  <div {...props}>
    <Label>SCORE</Label>
    <Number>{score}</Number>
  </div>
)

export default Score
