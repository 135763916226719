import styled from 'styled-components'
import lines from '../../assets/lines.png'

const LinesBackground = styled.div`
  background: url(${lines});
  background-repeat: repeat;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

export default LinesBackground
