import React from 'react'
import './App.css'
import screens from './screens'
import Router from './Router'

import * as websocket from './state/websocket'

websocket.init()

const App = () => <Router initialScreen={screens.SPLASH_SCREEN} screens={screens} />

export default App
