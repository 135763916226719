import React, { Component } from 'react'
import styled from 'styled-components'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import LinesBackground from '../components/backgrounds/LinesBackground'
import Content from '../components/position/Content'
import TopLeft from '../components/position/TopLeft'
import BottomRight from '../components/position/BottomRight'
import Title from '../components/text/Title'
import BodyText from '../components/text/BodyText'
import CloseButton from '../components/buttons/CloseButton'
import RightArrowButton from '../components/buttons/RightArrowButton'
import SyncButton from '../assets/syncButton.png'

// Preload the level images
import preloadImages from '../utils/preloadImages'
import LevelChamonix from '../assets/levelChamonix.jpg'
import LevelYosemite from '../assets/levelYosemite.jpg'
import LevelZermatt from '../assets/levelZermatt.jpg'
import LevelSquamish from '../assets/levelSquamish.jpg'

const Image = styled.img`
  margin-top: 20px;
`

class Sync extends Component {
  componentDidMount = () => {
    preloadImages([LevelChamonix, LevelYosemite, LevelZermatt, LevelSquamish])
  }

  render = () => (
    <>
      <MapBackground />
      <LinesBackground />

      <Content>
        <Title>SYNC</Title>

        <BodyText maxWidth={400}>
          Before you can start, please push this button on the top of your Motherboard. This will sync it with the Pi.
        </BodyText>

        <Image src={SyncButton} alt="sync button" />
      </Content>

      <TopLeft>
        <CloseButton onClick={() => this.props.navigate.reset()} />
      </TopLeft>

      <BottomRight>
        <RightArrowButton onClick={() => this.props.navigate.changeScreen(screens.LEVELS_SCREEN)} />
      </BottomRight>
    </>
  )
}

export default Sync
