import styled from 'styled-components'

const TextButton = styled.button`
  flex: 1;
  font-family: 'Archivo', sans-serif;
  background: #00dd81;
  border: none;
  min-height: 52px;
  box-shadow: 8px 8px 0px 0px #000;
  padding: 0;
  margin: 0;
  min-width: ${props => `${props.minWidth}px` || 'none'};
`

export default TextButton
