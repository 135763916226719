import styled from 'styled-components'

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
`

export default Button
