import React from 'react'
import Button from './Button'
import { ReactComponent as Close } from '../../assets/close.svg'

const CloseButton = props => (
  <Button {...props}>
    <Close />
  </Button>
)

export default CloseButton
