import React, { Component } from 'react'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import LinesBackground from '../components/backgrounds/LinesBackground'
import Content from '../components/position/Content'
import TopLeft from '../components/position/TopLeft'
import BottomRight from '../components/position/BottomRight'
import Title from '../components/text/Title'
import ControlImages from '../components/controls/ControlImages'
import BodyText from '../components/text/BodyText'
import CloseButton from '../components/buttons/CloseButton'
import RightArrowButton from '../components/buttons/RightArrowButton'
import { ReactComponent as BeastmakerHang } from '../assets/beastmakerHang.svg'

// Preload some images
import preloadImages from '../utils/preloadImages'
import HeliTakeoff from '../assets/heliTakeoff.png'
import Heli from '../assets/heli.png'
import HeliStop from '../assets/heliStop.png'
import HeliLower from '../assets/heliLower.png'
import HeliLowerStraight from '../assets/heliLowerStraight.png'
import HeliRaise from '../assets/heliRaise.png'

class Controls1 extends Component {
  componentDidMount = () => {
    preloadImages([HeliTakeoff, Heli, HeliStop, HeliLower, HeliLowerStraight, HeliRaise])
  }

  render = () => {
    const { navigate, screenOptions } = this.props

    return (
      <>
        <MapBackground />
        <LinesBackground />

        <Content>
          <Title>CONTROLS</Title>

          <BodyText maxWidth={400}>Control the game using your hangboard</BodyText>

          <ControlImages>
            <BeastmakerHang />
          </ControlImages>
        </Content>

        <TopLeft>
          <CloseButton
            onClick={() => (screenOptions.return ? navigate.changeScreen(screenOptions.return) : navigate.reset())}
          />
        </TopLeft>

        <BottomRight>
          <RightArrowButton onClick={() => navigate.changeScreen(screens.CONTROLS2_SCREEN, screenOptions)} />
        </BottomRight>
      </>
    )
  }
}

export default Controls1
