const Cesium = window.Cesium

export default class GameScorer {
  constructor(levelLoader) {
    this.samples = {}
    this.scores = {}
    this.levelLoader = levelLoader
  }

  setScore(levelId, routeId, sectionId, score) {
    this.scores[levelId][routeId][sectionId] = score
  }

  getScore(levelId, routeId, sectionId) {
    let levelScores = this.scores[levelId]
    if (levelScores == null) {
      levelScores = this.scores[levelId] = {totalScore: 0}
    }

    let routeScores = levelScores[routeId]
    if (routeScores == null) {
      routeScores = levelScores[routeId] = {}
    }

    return routeScores[sectionId]
  }

  // getTotal() {
  //   return _.mapValues(this.scores, levelScores => _.sum(
  //     _.mapValues(levelScores, routeScores => _.sum(
  //       _.mapValues(routeScores, sectionScores => _.sum(sectionScores))
  //     ))
  //   ))
  // }

  getTotal(levelId) {
    return this.scores[levelId].totalScore
  }

  addSample(sampleData) {
    let levelSamples = this.samples[sampleData.levelId]
    if (levelSamples == null) {
      levelSamples = this.samples[sampleData.levelId] = {}
    }

    let routeSamples = levelSamples[sampleData.routeId]
    if (routeSamples == null) {
      routeSamples = levelSamples[sampleData.routeId] = {}
    }

    let sectionSamples = routeSamples[sampleData.sectionId]
    if (sectionSamples == null) {
      sectionSamples = routeSamples[sampleData.sectionId] = []
    }

    sectionSamples.push([
      sampleData.time,
      sampleData.position.clone(),
      sampleData.isHanging,
    ])

    this.updateScore(sampleData.levelId, sampleData.routeId, sampleData.sectionId)
  }

  updateScore(levelId, routeId, sectionId) {
    const section = this.levelLoader.getLevel(levelId).getSection(
      routeId,
      sectionId
    )
    const sectionSamples = this.samples[levelId][routeId][sectionId]

    let score = this.getScore(levelId, routeId, sectionId) || 0
    let sample = sectionSamples[sectionSamples.length - 1]
    let prevSample = sectionSamples[sectionSamples.length - 2]
    if (prevSample != null) {
      const segmentScore = this.calculateScoreSegment(sample, prevSample, section)
      score += segmentScore
      this.scores[levelId].totalScore += segmentScore
    }
    this.setScore(levelId, routeId, sectionId, score)
  }

  scoreDistance(dist) {
    if (dist < 1) {
      return 10
    }
    if (dist < 5) {
      return 10 - dist
    }
    if (dist < 10) {
      return 5 - (dist - 5) / 2.5
    }
    if (dist < 20) {
      return 3 - (dist - 10) / 10
    }
    if (dist < 50) {
      return 2 - (dist - 20) / 30
    }
    if (dist < 100) {
      return 1 - (dist - 50) / 100
    }
    if (dist < 200) {
      return 1 - (dist - 100) / 200
    }
    return 0
  }

  findTargetPoint(sample, section) {
    let pointOnTargetLine = new Cesium.Cartesian3()
    pointOnTargetLine = Cesium.Cartesian3.multiplyByScalar(
      section.gradient,
      sample[0] * section.speed,
      pointOnTargetLine
    )
    pointOnTargetLine = Cesium.Cartesian3.add(section.startPosition, pointOnTargetLine, pointOnTargetLine)
    return pointOnTargetLine
  }

  calculateScoreSegment(sample, prevSample, section) {
    const dt = sample[0] - prevSample[0]
    const distHere = Cesium.Cartesian3.distance(
      sample[1],
      this.findTargetPoint(sample, section)
    )
    const prevDist = Cesium.Cartesian3.distance(
      prevSample[1],
      this.findTargetPoint(prevSample, section)
    )
    const areaFactor = 1000 / (10 * section.maxTime)
    const distScore = this.scoreDistance(distHere)
    const prevScore = this.scoreDistance(prevDist)
    console.log('DISTSCORE', distScore)
    console.log('PREVSCORE', prevScore)
    console.log('DT', dt)
    const area = (distScore + prevScore) * dt / 2
    return area * areaFactor
  }
}
