import React from 'react'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import LinesBackground from '../components/backgrounds/LinesBackground'
import ControlImages from '../components/controls/ControlImages'
import Content from '../components/position/Content'
import TopLeft from '../components/position/TopLeft'
import BottomLeft from '../components/position/BottomLeft'
import BottomRight from '../components/position/BottomRight'
import Title from '../components/text/Title'
import BodyText from '../components/text/BodyText'
import CloseButton from '../components/buttons/CloseButton'
import LeftArrowButton from '../components/buttons/LeftArrowButton'
import RightArrowButton from '../components/buttons/RightArrowButton'
import { ReactComponent as BeastmakerHang } from '../assets/beastmakerHang.svg'
import HeliLowerStraight from '../assets/heliLowerStraight.png'

const Controls6 = ({ navigate, screenOptions }) => (
  <>
    <MapBackground />
    <LinesBackground />

    <Content>
      <Title>CONTROLS</Title>

      <BodyText maxWidth={400}>Try to keep the winch lowering straight by hanging evenly with both hands</BodyText>

      <ControlImages>
        <img src={HeliLowerStraight} alt="helicopter" width={180} height={203} />
        <BeastmakerHang />
      </ControlImages>
    </Content>

    <TopLeft>
      <CloseButton
        onClick={() => (screenOptions.return ? navigate.changeScreen(screenOptions.return) : navigate.reset())}
      />
    </TopLeft>

    <BottomLeft>
      <LeftArrowButton onClick={() => navigate.changeScreen(screens.CONTROLS5_SCREEN, screenOptions)} />
    </BottomLeft>

    <BottomRight>
      <RightArrowButton onClick={() => navigate.changeScreen(screens.CONTROLS7_SCREEN, screenOptions)} />
    </BottomRight>
  </>
)

export default Controls6
