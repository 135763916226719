import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LeftArrow } from '../../assets/leftArrow.svg'

const Button = styled.button`
  background: #00dd81;
  border: none;
  min-height: 48px;
  min-width: 48px;
  box-shadow: 8px 8px 0px 0px #00143a;
`

const LeftArrowButton = props => (
  <Button {...props}>
    <LeftArrow />
  </Button>
)

export default LeftArrowButton
