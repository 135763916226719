import Calibrate from './Calibrate'
import Controls1 from './Controls1'
import Controls2 from './Controls2'
import Controls3 from './Controls3'
import Controls4 from './Controls4'
import Controls5 from './Controls5'
import Controls6 from './Controls6'
import Controls7 from './Controls7'
import LevelOne from './LevelOne'
import Levels from './Levels'
import Game from './Game'
import Splash from './Splash'
import Sync from './Sync'

const screens = {
  CALIBRATE_SCREEN: Calibrate,
  CONTROLS1_SCREEN: Controls1,
  CONTROLS2_SCREEN: Controls2,
  CONTROLS3_SCREEN: Controls3,
  CONTROLS4_SCREEN: Controls4,
  CONTROLS5_SCREEN: Controls5,
  CONTROLS6_SCREEN: Controls6,
  CONTROLS7_SCREEN: Controls7,
  LEVEL_ONE_SCREEN: LevelOne,
  LEVELS_SCREEN: Levels,
  SPLASH_SCREEN: Splash,
  GAME_SCREEN: Game,
  SYNC_SCREEN: Sync,
}

export default screens
