const Cesium = window.Cesium

export default class StraightSection {
  constructor({ startPosition, startHeading, startPitch, speed = 140, time = 7 }) {
    if (!(startPosition instanceof Cesium.Cartesian3)) {
      this.startPosition = Cesium.Cartesian3.fromDegrees.apply(null, startPosition)
    }
    else {
      this.startPosition = startPosition
    }
    this.startHeading = startHeading
    this.startPitch = startPitch
    this.startHPR = new Cesium.HeadingPitchRoll(startHeading, startPitch, 0)
    this.speed = speed
    this.maxTime = time
    this.distance = this.speed * this.maxTime
    this.matrix = Cesium.Transforms.headingPitchRollToFixedFrame(
      this.startPosition,
      new Cesium.HeadingPitchRoll(startHeading, startPitch, 0),
      Cesium.Ellipsoid.WGS84,
      Cesium.Transforms.localFrameToFixedFrameGenerator('north', 'west')
    )
    this.offset = new Cesium.Cartesian3(this.distance, 0, 0)
    this.endPosition = new Cesium.Cartesian3()
    this.endPosition = Cesium.Matrix4.multiplyByPoint(this.matrix, this.offset, this.endPosition)
    this.gradient = new Cesium.Cartesian3()
    this.gradient = Cesium.Cartesian3.subtract(this.endPosition, this.startPosition, this.gradient)
    this.gradient = Cesium.Cartesian3.normalize(this.gradient, this.gradient)
  }
}

