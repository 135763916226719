import React from 'react'
import Button from './Button'
import { ReactComponent as BackArrow } from '../../assets/backArrow.svg'

const BackButton = props => (
  <Button {...props}>
    <BackArrow />
  </Button>
)

export default BackButton
