import styled from 'styled-components'
import backgroundMap from '../../assets/backgroundMap@2x.jpg'

const MapBackground = styled.div`
  background: ${props => (props.image ? `url(${props.image})` : `url(${backgroundMap})`)} #a3cffe;
  background-size: cover;
  background-position: center top;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

export default MapBackground
