import React from 'react'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import LinesBackground from '../components/backgrounds/LinesBackground'
import ControlImages from '../components/controls/ControlImages'
import Content from '../components/position/Content'
import TopLeft from '../components/position/TopLeft'
import BottomLeft from '../components/position/BottomLeft'
import BottomRight from '../components/position/BottomRight'
import Title from '../components/text/Title'
import BodyText from '../components/text/BodyText'
import CloseButton from '../components/buttons/CloseButton'
import LeftArrowButton from '../components/buttons/LeftArrowButton'
import TextButton from '../components/buttons/TextButton'
import HeliRaise from '../assets/heliRaise.png'

const Controls7 = ({ navigate, screenOptions }) => (
  <>
    <MapBackground />
    <LinesBackground />

    <Content>
      <Title>CONTROLS</Title>

      <BodyText maxWidth={400}>If you’re successful you’ll rescue the hiker</BodyText>

      <ControlImages>
        <img src={HeliRaise} alt="helicopter" width={180} height={203} />
      </ControlImages>
    </Content>

    <TopLeft>
      <CloseButton
        onClick={() => (screenOptions.return ? navigate.changeScreen(screenOptions.return) : navigate.reset())}
      />
    </TopLeft>

    <BottomLeft>
      <LeftArrowButton onClick={() => navigate.changeScreen(screens.CONTROLS6_SCREEN, screenOptions)} />
    </BottomLeft>

    <BottomRight>
      <TextButton
        minWidth={200}
        onClick={() =>
          screenOptions.play ? navigate.changeScreen(screenOptions.play) : navigate.changeScreen(screens.SYNC_SCREEN)
        }
      >
        {screenOptions.play ? 'PLAY' : 'PLAY'}
      </TextButton>
    </BottomRight>
  </>
)

export default Controls7
