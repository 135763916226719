import React from 'react'
import styled from 'styled-components'
import { ReactComponent as RightArrow } from '../../assets/rightArrow.svg'

const Button = styled.button`
  background: #00dd81;
  border: none;
  min-height: 48px;
  min-width: 48px;
  box-shadow: -8px 8px 0px 0px #00143a;
`

const RightArrowButton = props => (
  <Button {...props}>
    <RightArrow />
  </Button>
)

export default RightArrowButton
