import React from 'react'
import Button from './Button'
import { ReactComponent as HowTo } from '../../assets/howTo.svg'

const HowToButton = props => (
  <Button {...props}>
    <HowTo />
  </Button>
)

export default HowToButton
