import React, { Component } from 'react'
import styled from 'styled-components'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import TopLeft from '../components/position/TopLeft'
import Content from '../components/position/Content'
import BackButton from '../components/buttons/BackButton'
import { ReactComponent as LevelOneTitle } from '../assets/levelOneTitle.svg'
import TextButton from '../components/buttons/TextButton'
import Title from '../components/text/Title'
import levelOneBackground from '../assets/levelOneBackground@2x.jpg'

const Buttons = styled.div`
  display: flex;
  width: 500px;
  position: fixed;
  bottom: 20px;
`

const RoamButton = styled(TextButton)`
  background: #fff;
  margin-right: 15px;
`

const MissionsButton = styled(TextButton)`
  margin-left: 15px;
`

class LevelOne extends Component {
  render = () => (
    <>
      <MapBackground image={levelOneBackground} />

      <Content paddingTop={4}>
        <Title fontSize={18}>Level 1</Title>
        <LevelOneTitle />

        <Buttons>
          <RoamButton
            onClick={() =>
              this.props.navigate.changeScreen(screens.CONTROLS1_SCREEN, {
                return: screens.LEVEL_ONE_SCREEN,
                play: screens.GAME_SCREEN,
              })
            }
          >
            HOW TO PLAY
          </RoamButton>
          <MissionsButton onClick={() => this.props.navigate.changeScreen(screens.GAME_SCREEN)}>START</MissionsButton>
        </Buttons>
      </Content>

      <TopLeft>
        <BackButton onClick={() => this.props.navigate.changeScreen(screens.LEVELS_SCREEN)} />
      </TopLeft>
    </>
  )
}

export default LevelOne
