import leftPad from 'left-pad'

const secondsToHours = (seconds: number) => Math.floor(seconds / 3600)

const secondsToMinutes = (seconds: number) => Math.floor((seconds % 3600) / 60)

const secondsToSeconds = (seconds: number) => Math.floor(seconds % 60)

const pad = (minutes: number) => leftPad(minutes, 2, '0')

type Options = {
  forceHours?: boolean,
}

const secondsToHoursMinutesSeconds = (totalSeconds: number, { forceHours }: Options = {}): string => {
  const hours = secondsToHours(totalSeconds)
  const minutes = secondsToMinutes(totalSeconds)
  const seconds = secondsToSeconds(totalSeconds)

  return `${hours || forceHours ? `${pad(hours)}:` : ''}${pad(minutes)}:${pad(seconds)}`
}

export default secondsToHoursMinutesSeconds
