import React, { Component } from 'react'
import styled from 'styled-components'
import Spinner from 'react-spinkit'
import screens from '../screens'
import MapBackground from '../components/backgrounds/MapBackground'
import TopLeft from '../components/position/TopLeft'
import TopRight from '../components/position/TopRight'
import Content from '../components/position/Content'
import HowToButton from '../components/buttons/HowToButton'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { ReactComponent as SplashHeli } from '../assets/splashHeli.svg'
import { ReactComponent as Title } from '../assets/title.svg'
import TextButton from '../components/buttons/TextButton'
import * as websocket from '../state/websocket'

// Preload some images and fonts
import preloadImages from '../utils/preloadImages'
import SyncButton from '../assets/syncButton.png'
import FontPreload from '../components/FontPreload'
import BodyText from '../components/text/BodyText'

const ContentStyled = styled(Content)`
  min-height: auto;
`

const HeliStyled = styled(SplashHeli)`
  z-index: 2;
  position: relative;
  left: -20px;
`

const TitleStyled = styled(Title)`
  z-index: 1;
  position: relative;
  top: -35px;
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  top: -20px;
`

const RoamButton = styled(TextButton)`
  background: #fff;
  margin-right: 15px;
`

const MissionsButton = styled(TextButton)`
  margin-left: 15px;
`

const Warning = styled.p`
  background: #000;
  padding: 4px;
  margin-bottom: 4px;
`

class Splash extends Component {
  constructor(props) {
    super(props)

    this.state = {
      landscape: null,
      websocketConnected: false,
    }
  }

  componentDidMount = () => {
    preloadImages([SyncButton])

    this._checkWebsocket()
  }

  componentWillUnmount = () => {
    clearTimeout(this.WEBSOCKET_TIMEOUT)
  }

  _checkWebsocket = () => {
    if (websocket.isConnected()) {
      this.setState({ websocketConnected: true })
    }

    this.WEBSOCKET_TIMEOUT = setTimeout(this._checkWebsocket, 500)
  }

  render = () => (
    <>
      <MapBackground />

      <ContentStyled paddingTop={35}>
        <HeliStyled />
        <TitleStyled />

        <Buttons>
          <RoamButton onClick={() => this.props.navigate.changeScreen(screens.GAME_SCREEN, { mode: 'freeFly' })}>
            FREE ROAM
          </RoamButton>
          <MissionsButton onClick={() => this.props.navigate.changeScreen(screens.SYNC_SCREEN)}>
            MISSIONS
          </MissionsButton>
        </Buttons>

        {!this.state.websocketConnected && (
          <>
            <BodyText as={Warning}>No controller connected yet.</BodyText>
            <Spinner name="three-bounce" color="#FFF" />
          </>
        )}

        {this.state.websocketConnected && <BodyText as={Warning}>Controller connected</BodyText>}
      </ContentStyled>

      <TopLeft>
        <Logo />
      </TopLeft>

      <TopRight>
        <HowToButton onClick={() => this.props.navigate.changeScreen(screens.CONTROLS1_SCREEN)} />
      </TopRight>

      <FontPreload />
    </>
  )
}

export default Splash
