import React, { Component } from 'react'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = { currentScreen: props.initialScreen, screenOptions: {} }
  }

  _reset = () => {
    this.setState({ currentScreen: this.props.initialScreen, screenOptions: {} })
  }

  _changeScreen = (screen, screenOptions = {}) => {
    this.setState({ currentScreen: screen, screenOptions })
  }

  render = () => {
    const Screen = this.state.currentScreen
    const navigate = { changeScreen: this._changeScreen, reset: this._reset }

    return <Screen navigate={navigate} screenOptions={this.state.screenOptions} />
  }
}

export default App
