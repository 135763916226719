import { getAGL, getCartographicInDirection } from '../utils/GameUtils.js'
import * as websocket from './websocket'

import { LevelLoader } from '../utils/Level.js'
import GameScorer from '../utils/GameScore.js'

let directionInput = 0
let isHanging = false

// try {
//   ws = new WebSocket('ws://172.24.1.1:10724/')

// ws.onmessage = event => {
//   wsWorking = true
//   const parts = JSON.parse(event.data)
//   directionInput = parts[0]
// }
// console.log('ws', ws)
// }
// catch (error) {
//   console.log('websocket error', error)
//   // noop
// }

const Cesium = window.Cesium

const HELI_SPEED = 140
const MAX_DROP_TIME = 5
const STATE_SECTION_START = Symbol('SECTION_START')
const STATE_SECTION_HANG = Symbol('SECTION_HANG')
const STATE_SECTION_DROP = Symbol('SECTION_DROP')
const STATE_SECTION_END = Symbol('SECTION_END')
const STATE_SECTION_TWEEN = Symbol('SECTION_TWEEN')
const STATE_BREAK = Symbol('BREAK')
const STATE_LOADING = Symbol('LOADING')
// const STATE_FREE_FLY = Symbol('FREE_FLY')
const STATE_SUCCESS = Symbol('SUCCESS')
const STATE_FAILURE = Symbol('FAILURE')

export default class GameState {
  constructor(viewer, component, {
    levelId = 0,
    mode = 'main',
    eventHandler = () => {}
  } = {}) {
    this.viewer = viewer
    this.component = component

    this.levelLoader = new LevelLoader()
    this.scorer = new GameScorer(this.levelLoader)

    this.level = this.levelLoader.getLevel(levelId)
    this.route = this.level.getRoute(0)
    this.section = this.level.getSection(0, 0)

    this.mode = mode

    this.heliPosition = Cesium.Cartesian3.fromDegrees(6.866922, 45.923554, 1300)
    this.heliPositionCartographic = Cesium.Cartographic.fromCartesian(this.heliPosition)
    this.heliHPR = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(270), 0, 0)
    this.heliSpeed = 0
    this.heliVelocity = new Cesium.Cartesian3()
    this.heliVelocityAngle = 0

    this.heliAGL = 500
    this.targetAGL = 500

    this.camera = this.viewer.camera
    this.cameraCenter = new Cesium.Cartesian3()
    this.cameraOffset = new Cesium.HeadingPitchRange()

    this.directionInput = 0

    this.fixedFrameTransform = Cesium.Transforms.localFrameToFixedFrameGenerator('north', 'west')

    this.lastUpdateTime = null
    this.timeSinceLastAngleUpdate = 100
    this.paused = true
    // this.awaitingTerrainLoad = true

    this.emitEvent = eventHandler

    websocket.onmessage(event => {
      const parts = JSON.parse(event.data)
      directionInput = parts[0]
      isHanging = parts[1] > 25
    })
  }

  getHeliVelocityDirection(nextAGL, targetAGL) {
    const diff = nextAGL - targetAGL
    if (Math.abs(diff) < 3) {
      return 0
    }
    return Math.atan2(-diff, 50)
  }

  displayRoutePaths(route) {
    this.targetPathEntities = (route || this.route).sections.map(s => this.viewer.entities.add({
      polyline: {
        positions: [s.startPosition, s.endPosition],
        // computePositions([
        //   [6.866922, 45.923554, 1302],
        //   [6.863, 45.923, 1280],
        //   [6.86, 45.923554, 1302],
        // ]),
        width: 10,
        material: new Cesium.Color(1, 1, 0, 0.5),
      },
    }))
    this.previousPathEntities = []
  }

  cleanupRoutePaths() {
    this.targetPathEntities.forEach(entity => this.viewer.entities.remove(entity))
    this.targetPathEntities = []
  }

  cleanupRecordedPath() {
    this.viewer.entities.remove(this.currentPathEntity)
    this.currentPathEntity = null
    this.previousPathEntities.forEach(entity => this.viewer.entities.remove(entity))
    this.previousPathEntities = []
  }

  displayRecordedPath() {
    this.currentPathEntity = this.viewer.entities.add({
      position: this.pathPosition,
      path: {
        show: true,
        leadTime: 0,
        trailTime: 300,
        width: 10,
        resolution: 0.5,
        material: Cesium.Color.RED,
      },
    })
  }

  // clearSectionDisplay() {
  //   this.targetPathEntities.forEach(entity => this.viewer.entities.remove(entity))
  //   this.viewer.entities.remove(this.currentPathEntity)
  //   this.targetPathEntities = []
  //   this.currentPathEntity = null
  //   this.currentPathEntities.forEach(entity => this.viewer.entities.remove(entity))
  // }

  setupRoute() {
    this.route = this.nextRoute || this.route
    this.nextRoute = this.level.getNextRoute(this.route.id)
  }

  setupSection() {
    this.section = this.nextSection || this.section
    this.nextSection = this.route.getNextSection(this.section.id)

    this.heliPosition = this.section.startPosition.clone()
    this.heliHPR = new Cesium.HeadingPitchRoll(
      this.section.startHeading,
      0,
      0
    )
    this.heliVelocity.x = this.heliSpeed * Math.cos(this.section.startPitch)
    this.heliVelocity.y = 0
    this.heliVelocity.z = this.heliSpeed * Math.sin(this.section.startPitch)
    if (this.heli != null) {
      Cesium.Transforms.headingPitchRollToFixedFrame(
        this.heliPosition,
        this.heliHPR,
        Cesium.Ellipsoid.WGS84,
        this.fixedFrameTransform,
        this.heli.modelMatrix
      )
    }
    this.pathPosition = new Cesium.SampledPositionProperty()
    this.displayRecordedPath()
  }

  endSection() {
    this.previousPathEntities.push(this.currentPathEntity)
    this.pathPosition = new Cesium.SampledPositionProperty()
  }

  setupMainMode() {
    this.setupRoute()
    this.setupSection()
    this.displayRoutePaths()

    this.onTick = this.onTickMain
    this.updateHeliOrientation = this.updateHeliOrientationMain
    this.updateHeliVelocity = this.updateHeliVelocityMain
    this.updateHeliPosition = this.updateHeliPositionMain

    this.heliVelocity.x = this.heliSpeed
    // this.pathSamples = []
  }

  clearMainMode() {
    this.clearSectionDisplay()
  }

  setupFreeFly() {
    this.heliPosition = Cesium.Cartesian3.fromDegrees(6.866922, 45.923554, 1300)
    this.heliPositionCartographic = Cesium.Cartographic.fromCartesian(this.heliPosition)
    this.heliAGL = 500
    this.targetAGL = 500
    this.heliSpeed = HELI_SPEED
    this.heliHPR = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(270), 0, 0)

    this.onTick = this.onTickFreeFly
    this.updateHeliOrientation = this.updateHeliOrientationFreeFly
    this.updateHeliVelocity = this.updateHeliVelocityFreeFly
    this.updateHeliPosition = this.updateHeliPositionFreeFly
  }

  setupTweener(startPosition, startHpr, targetPosition, targetHpr, duration) {
    let offsetPosition = new Cesium.Cartesian3()
    Cesium.Cartesian3.subtract(targetPosition, startPosition, offsetPosition)
    let offsetHpr = new Cesium.HeadingPitchRoll(
      targetHpr.heading - startHpr.heading,
      targetHpr.pitch - startHpr.pitch,
      targetHpr.roll - startHpr.roll
    )
    return (time) => {
      let currentPosition = new Cesium.Cartesian3()
      Cesium.Cartesian3.multiplyByScalar(offsetPosition, time / duration, currentPosition)
      Cesium.Cartesian3.add(startPosition, currentPosition, currentPosition)
      let currentHpr = startHpr.clone()
      currentHpr.heading += offsetHpr.heading * time / duration
      // currentHpr.pitch += offsetHpr.pitch * time / duration
      currentHpr.roll += offsetHpr.roll * time / duration
      return {
        matrix: Cesium.Transforms.headingPitchRollToFixedFrame(
          currentPosition,
          currentHpr,
          Cesium.Ellipsoid.WGS84,
          this.fixedFrameTransform,
        ),
        position: currentPosition,
        hpr: currentHpr
      }
    }
  }

  setup() {
    if (this.mode === 'main') {
      this.setupMainMode()
    }
    else {
      this.setupFreeFly()
    }
    this.heli = this.viewer.scene.primitives.add(
      Cesium.Model.fromGltf({
        url: `${process.env.PUBLIC_URL}/heli6/scene.gltf`,
        modelMatrix: Cesium.Transforms.headingPitchRollToFixedFrame(
          this.heliPosition,
          this.heliHPR,
          Cesium.Ellipsoid.WGS84,
          this.fixedFrameTransform
        ),
        // minimumPixelSize: 128,
      })
    )
    this.heli.readyPromise.then(model => {
      this.heli.activeAnimations.addAll({
        loop: Cesium.ModelAnimationLoop.REPEAT,
      })
      this.updateCamera()
      this.lastUpdateTime = Cesium.JulianDate.now()
      this.viewer.scene.preRender.addEventListener((scene, time) => {
        this.onTick(scene, time)
      })
    })

    document.onmousemove = event => {
      this.lastMouseRatio = event.pageX / window.innerWidth
    }
    document.addEventListener('keydown', event => {
      if (event.code === 'Space') {
        this.mouseDown = true
        return false
      }
    })
    document.addEventListener('keyup', event => {
      if (event.code === 'Space') {
        this.mouseDown = false
        return false
      }
    })
    // let handler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas)
    // handler.setInputAction(event => {
    //   this.mouseDown = false
    // }, Cesium.ScreenSpaceEventType.LEFT_UP)
    // handler.setInputAction(event => {
    //   this.mouseDown = true
    // }, Cesium.ScreenSpaceEventType.LEFT_DOWN)
  }

  getInput() {
    if (websocket.isConnected()) {
      this.directionInput = directionInput
      this.isHanging = isHanging
    }
    else {
      this.directionInput = (this.lastMouseRatio || 0.5) * 2 - 1
      this.isHanging = this.mouseDown
    }
  }

  updateHeliOrientationMain() {
    this.heliHPR.heading += (this.directionInput / 2.5) * this.dt
    // this.heliHPR.pitch = this.currentSample[2].pitch
    // this.heliHPR.roll = this.currentSample[2].roll
  }

  updateHeliOrientationFreeFly() {
    this.heliHPR.heading = this.heliHPR.heading + (this.directionInput / 5) * this.dt
  }

  updateHeliVelocityMain() {
    // velocity is constant so should just be a noop
  }

  updateHeliVelocityFreeFly() {
    if (this.timeSinceLastAngleUpdate > 0.5) {
      let nextAGL = getAGL(
        getCartographicInDirection(this.heliPositionCartographic, this.heliHPR.heading + Math.PI, 50),
        this.viewer.scene
      )
      this.targetAngle = this.getHeliVelocityDirection(nextAGL, this.targetAGL)
      this.timeSinceLastAngleUpdate = 0
    }
    let angleDiff = this.targetAngle - this.heliVelocityAngle
    if (Math.abs(angleDiff) > this.dt) {
      this.heliVelocityAngle += (angleDiff > 0 ? 1 : -1) * this.dt
    }
    this.heliVelocityAngle = Math.min(this.heliVelocityAngle, Math.PI / 2)
    this.heliVelocity.x = this.heliSpeed * Math.cos(this.heliVelocityAngle)
    this.heliVelocity.y = 0
    this.heliVelocity.z = this.heliSpeed * Math.sin(this.heliVelocityAngle)
  }

  updateHeliPositionCommon() {
    // debugger;
    let heliOffset = new Cesium.Cartesian3()
    heliOffset = Cesium.Cartesian3.multiplyByScalar(this.heliVelocity, this.dt, heliOffset)
    this.heliPosition = Cesium.Matrix4.multiplyByPoint(this.heli.modelMatrix, heliOffset, this.heliPosition)
    Cesium.Transforms.headingPitchRollToFixedFrame(
      this.heliPosition,
      this.heliHPR,
      Cesium.Ellipsoid.WGS84,
      this.fixedFrameTransform,
      this.heli.modelMatrix
    )
  }

  updateHeliPositionMain() {
    this.updateHeliPositionCommon()
  }

  updateHeliPositionFreeFly() {
    this.updateHeliPositionCommon()
    this.heliPositionCartographic = Cesium.Cartographic.fromCartesian(this.heliPosition)
    // this.heliAGL = getAGL(this.heliPositionCartographic, this.viewer.scene)
  }

  updateCamera() {
    let r = 2.0 * Math.max(this.heli.boundingSphere.radius, this.camera.frustum.near)
    this.cameraCenter = Cesium.Matrix4.multiplyByPoint(
      this.heli.modelMatrix,
      this.heli.boundingSphere.center,
      this.cameraCenter
    )

    let pitch = Cesium.Math.toRadians(-15)
    this.cameraOffset.heading = this.heliHPR.heading
    this.cameraOffset.pitch = pitch
    this.cameraOffset.range = r * 3
    this.camera.lookAt(this.cameraCenter, this.cameraOffset)
  }

  isLoaded() {
    return (
      !!this.viewer.scene.globe._surface._tilesToRender.length &&
      !this.viewer.scene.globe._surface._tileLoadQueueHigh.length &&
      !this.viewer.scene.globe._surface._tileLoadQueueMedium.length &&
      !this.viewer.scene.globe._surface._tileLoadQueueLow.length
    )
  }

  start() {
    this.startTime = this.time.clone()
    this.paused = false

    this.state = STATE_SECTION_START
    this.emitEvent('started')
  }

  pause() {
    this.viewer.clock.shouldAnimate = false
    this.paused = true

    this.emitEvent('paused')
    this.emitEvent('timerPaused')
  }

  resume() {
    this.viewer.clock.shouldAnimate = true
    this.paused = false

    this.emitEvent('resumed')
    this.emitEvent('timerResumed')
  }

  onTickCommon(scene, time) {
    this.time = time
    this.dt = Cesium.JulianDate.secondsDifference(time, this.lastUpdateTime)
    this.lastUpdateTime = time
  }

  onTickMain(scene, time) {
    this.onTickCommon(scene, time)
    this.getInput()

    if (this.paused) {
      return
    }

    if (this.state === STATE_SECTION_START && !this.isHanging) {
      this.updateCamera()
      if (this.section.id !== 0) {
        const timeSinceWaitStart = Cesium.JulianDate.secondsDifference(this.time, this.sectionStartedWaitingTime)
        if (timeSinceWaitStart > 7) {
          this.state = STATE_FAILURE
          this.emitEvent('failed')
        }
        if (!this._warnedStart && timeSinceWaitStart > 2) {
          this.emitEvent('hangWarningChanged', true)
          this._warnedStart = true
        }
      }
      return
    }

    if (this.state === STATE_SECTION_START) {
      this._warnedStart = false
      this.emitEvent('hangWarningChanged', false)
      this.sectionStartTime = this.time.clone()
      this.timeSinceStart = 0
      this.emitEvent('timerChanged', 7)
      this.emitEvent('timerPaused')
      this.heliSpeed = HELI_SPEED
      this.heliVelocity.x = this.heliSpeed * Math.cos(this.section.startPitch)
      this.heliVelocity.y = 0
      this.heliVelocity.z = this.heliSpeed * Math.sin(this.section.startPitch)
      this.updateHeliVelocity()
      this.state = STATE_SECTION_HANG
      this.emitEvent('timerResumed')
      console.warn('Changed state: SECTION_HANG')
    }

    if (this.state === STATE_SECTION_HANG) {
      this.timeSinceStart += this.dt
      if (this.timeSinceStart >= this.section.maxTime) {
        // end the section
        this.state = STATE_SECTION_END
        console.warn('Changed state: SECTION_END')
      }
      if (!this.isHanging) {
        this.emitEvent('hangWarningChanged', true)
        this.dropTime = this.time.clone()
        this.state = STATE_SECTION_DROP
        this.emitEvent('timerPaused')
        console.warn('Changed state: SECTION_DROP')
      }
    }

    if (this.state === STATE_SECTION_DROP) {
      const timeSinceDrop = Cesium.JulianDate.secondsDifference(this.time, this.dropTime)
      if (this.isHanging) {
        this.emitEvent('hangWarningChanged', false)
        this.state = STATE_SECTION_HANG
        this.emitEvent('timerResumed')
      }
      if (timeSinceDrop >= MAX_DROP_TIME) {
        this.state = STATE_FAILURE
        this.emitEvent('hangWarningChanged', false)
        this.emitEvent('failed')
      }
      return
    }

    if (this.state === STATE_SECTION_END) {
      if (this.isHanging) {
        return
      }
      this.sectionEndTime = this.time.clone()
      // this.clearSectionDisplay()

      this.endSection()

      if (this.nextSection == null) {
        console.warn('Changed state: BREAK')
        this.cleanupRecordedPath()
        this.cleanupRoutePaths()
        if (this.nextRoute != null) {
          this.emitEvent('timerChanged', 60)
          this.displayRoutePaths(this.nextRoute)
          this.state = STATE_BREAK
          this.routeEndTime = this.time.clone()
          this.nextSection = this.nextRoute.sections[0]
          this.tweener = this.setupTweener(
            this.heliPosition.clone(),
            this.heliHPR.clone(),
            this.nextRoute.sections[0].startPosition,
            this.nextRoute.sections[0].startHPR,
            60
          )
        }
        else {
          this.state = STATE_SUCCESS
          this.emitEvent('completed')
        }
      }
      else {
        console.warn('Changed state: SECTION_TWEEN')
        this.state = STATE_SECTION_TWEEN
        this.emitEvent('timerChanged', 3)
        this.tweener = this.setupTweener(
          this.heliPosition.clone(),
          this.heliHPR.clone(),
          this.nextSection.startPosition,
          this.nextSection.startHPR,
          3
        )
      }
    }
    if (this.state === STATE_BREAK) {
      const timeSinceTweenStart = Cesium.JulianDate.secondsDifference(this.time, this.routeEndTime)
      if (timeSinceTweenStart >= 60) {
        console.warn('Changed state: SECTION_START')
        this.state = STATE_SECTION_START
        this.sectionStartedWaitingTime = this.time.clone()
        this.setupRoute()
        this.setupSection()
        return
      }
      let tweenResult = this.tweener(timeSinceTweenStart)

      this.heliPosition = tweenResult.position
      this.heliHPR = tweenResult.hpr
      this.heli.modelMatrix = tweenResult.matrix

      this.updateCamera()
      return;
    }
    if (this.state === STATE_SECTION_TWEEN) {
      const timeSinceTweenStart = Cesium.JulianDate.secondsDifference(this.time, this.sectionEndTime)
      if (timeSinceTweenStart >= 3) {
        console.warn('Changed state: SECTION_START')
        this.state = STATE_SECTION_START
        this.sectionStartedWaitingTime = this.time.clone()
        this.setupSection()
        return
      }
      let tweenResult = this.tweener(timeSinceTweenStart)

      this.heliPosition = tweenResult.position
      this.heliHPR = tweenResult.hpr
      this.heli.modelMatrix = tweenResult.matrix

      this.updateCamera()
      return;
    }
    if (this.state === STATE_SUCCESS || this.state === STATE_FAILURE) {
      return;
    }


    this.updateHeliOrientation()
    this.updateHeliVelocity()
    this.updateHeliPosition()
    this.updateCamera()

    if (this.state === STATE_SECTION_HANG) {
      this.scorer.addSample({
        sectionId: this.section.id,
        routeId: this.route.id,
        levelId: this.level.id,
        time: this.timeSinceStart,
        position: this.heliPosition,
        isHanging: this.isHanging
      })
      this.emitEvent('scoreChanged', Math.floor(this.scorer.getTotal(this.level.id)))
      this.pathPosition.addSample(this.time.clone(), this.heliPosition)
    }
  }

  onTickFreeFly(scene, time) {
    this.onTickCommon(scene, time)

    if (this.paused) {
      return
    }

    this.timeSinceLastAngleUpdate += this.dt

    this.getInput()

    this.updateHeliOrientation()
    this.updateHeliVelocity()
    this.updateHeliPosition()
    this.updateCamera()
  }

  changeMode(newMode) {
    if (newMode === 'freeFly') {
      this.clearMainMode()
      this.setupFreeFly()
    }
    else {
      this.setupMainMode()
    }
    this.mode = newMode
  }
}

GameState.STATES = {
  STATE_LOADING,
  STATE_SECTION_START,
  STATE_SECTION_HANG,
  STATE_SECTION_TWEEN,
  STATE_SECTION_END,
  STATE_BREAK
}
